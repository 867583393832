import { createServiceManifest } from '@estee/elc-service';

import {
    EMAIL_OPT_IN,
    PRODUCT_NOTIFY_ME,
    VIEW_EMAIL_OPT_IN,
    HELPFUL_LINKS
} from '@estee/elc-service-view-names';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { events, serviceQueries as queries } from '@estee/elc-service-bus';
import { CommunicationsService } from './service-setup/CommunicationsService';

const serviceViews = [EMAIL_OPT_IN, PRODUCT_NOTIFY_ME, VIEW_EMAIL_OPT_IN, HELPFUL_LINKS];
const { name, version } = __serviceInfo__;

const serviceEvents = [
    events.GET_OPTIN_PROFILE_LOAD,
    events.AUTH_SUCCEDED,
    events.APPOINTMENT_BOOKED,
    events.CONTINUE_AS_GUEST_CLICKED,
    events.USER_PROFILE_CHANGED,
    events.SIGNED_OUT,
    events.PRODUCT_NOTIFY_ME_REQUEST,
    events.PRODUCT_NOTIFY_ME_REQUEST_SUCCESS,
    events.PRODUCT_NOTIFY_ME_REQUEST_FAILURE,
    events.PRODUCT_NOTIFY_ME_TRIGGERED
];

const serviceQueries = [queries.EMAIL_OPT_IN];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<
    CommunicationsService
>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries: serviceQueries,
        name,
        version
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'communicationsService'
);
